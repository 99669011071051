<template>
  <div></div>
</template>

<script setup lang="ts">
import { onMounted, computed } from "vue";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth";
import { useOrderStore } from "@/stores/order";

import { UPSELL_MODE, XSELL_MODE } from "@/helpers/constants";
import { useGlobalStore } from "@/stores/global";

const paramsString = window.location.search;
const searchParams = new URLSearchParams(paramsString);
const threeDsAuthId = searchParams.get("3ds_auth_id");
const responseDetail = searchParams.get("response_detail");
const result = searchParams.get("result");
const responseCode = searchParams.get("response_code");

const cartStore = useCartStore();
const authStore = useAuthStore();
const orderStore = useOrderStore();
const globalStore = useGlobalStore();

const products = computed(() => {
  if (cartStore.offerData[cartStore.orderNumberCvConfirm]) {
    return cartStore.offerData[cartStore.orderNumberCvConfirm].map((item: any) => {
      return {
        id: item.id,
        quantity: item.quantity,
      };
    });
  }

  return [];
});

const subsOrderData = computed(() => cartStore.offerData.subsOrderData);
const specifyDeliveryDate = computed(() => cartStore.offerData.specifyDeliveryDate);
const shippingCarrierId = computed(() => cartStore.offerData.shippingCarrierId);

onMounted(() => {
  if (!threeDsAuthId || !cartStore.currentBaseUrl) return;

  if (
    subsOrderData.value &&
    specifyDeliveryDate.value &&
    shippingCarrierId.value &&
    [UPSELL_MODE, XSELL_MODE].includes(cartStore.currentMode)
  ) {
    if (cartStore.currentMode === UPSELL_MODE) {
      submitCvUpsell();
    } else if (cartStore.currentMode === XSELL_MODE) {
      submitCvXsell();
    }
  } else {
    if (result === "1") {
      globalStore.keepError = true;
      globalStore.keepBaseError = true;
      globalStore.setValidationErrors({ base: { base: [responseDetail] } }, true);
      window.location.href = `/lp?u=${cartStore.currentBaseUrl}`;
      return;
    }

    cartStore
      .submitCart(null, threeDsAuthId)
      .then((data: any) => {
        if (data.token) authStore.setTokenAndCookie(data.token);

        orderStore.resetPageView(cartStore.currentBaseUrl);
        window.location.href = data.redirectUrl;
      })
      .catch(() => {
        cartStore.handleErrorWhenSubmit();
      });
  }
});

function submitCvUpsell() {
  const productId = cartStore.offerData[cartStore.orderNumberCvConfirm];

  cartStore
    .submitOfferCvUpsell(
      cartStore.orderNumberCvConfirm,
      productId,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
      null,
      threeDsAuthId,
    )
    .then((data: any) => {
      orderStore.resetPageView(cartStore.currentBaseUrl);

      window.location.href = data.redirectUrl;
    })
    .catch(() => redirectToFinishPage());
}

function submitCvXsell() {
  cartStore
    .submitOfferCvXsell(
      cartStore.orderNumberCvConfirm,
      products.value,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
      null,
      threeDsAuthId,
    )
    .then((data: any) => {
      cartStore.$reset();
      orderStore.resetPageView(cartStore.currentBaseUrl);

      window.location.href = data.redirectUrl;
    })
    .catch(() => redirectToFinishPage());
}

function redirectToFinishPage() {
  window.location.href = `/lp/finish?u=${cartStore.currentBaseUrl}&order_id=${cartStore.orderNumberCvConfirm}`;
}
</script>
