<template>
  <div></div>
</template>
<script setup lang="ts">
import { onMounted, computed } from "vue";

import { useCartStore } from "@/stores/cart";
import { useOrderStore } from "@/stores/order";

import { UPSELL_MODE, XSELL_MODE } from "@/helpers/constants";

const paramsString = window.location.search;
const searchParams = new URLSearchParams(paramsString);
const amazonCheckoutSessionId = searchParams.get("amazonCheckoutSessionId");

const cartStore = useCartStore();

const orderStore = useOrderStore();

const subsOrderData = computed(() => cartStore.offerData.subsOrderData);
const specifyDeliveryDate = computed(() => cartStore.offerData.specifyDeliveryDate);
const shippingCarrierId = computed(() => cartStore.offerData.shippingCarrierId);

onMounted(async () => {
  if (!amazonCheckoutSessionId || !cartStore.currentBaseUrl) return;

  if (
    subsOrderData.value &&
    specifyDeliveryDate.value &&
    shippingCarrierId.value &&
    [UPSELL_MODE, XSELL_MODE].includes(cartStore.currentMode)
  ) {
    if (cartStore.currentMode === UPSELL_MODE) {
      submitCvUpsell();
    } else if (cartStore.currentMode === XSELL_MODE) {
      submitCvXsell();
    }
  } else {
    cartStore
      .submitCart(amazonCheckoutSessionId)
      .then((data) => {
        orderStore.resetPageView(cartStore.currentBaseUrl);
        window.location.href = data.redirectUrl;
      })
      .catch(() => {
        //  TODOCHECK WHEN CATCH ERROR
        cartStore.handleErrorWhenSubmit();
      });
  }
});

const products = computed(() => {
  if (cartStore.offerData[cartStore.orderNumberCvConfirm]) {
    return cartStore.offerData[cartStore.orderNumberCvConfirm].map((item: any) => {
      return {
        id: item.id,
        quantity: item.quantity,
      };
    });
  }

  return [];
});
function submitCvUpsell() {
  const productId = cartStore.offerData[cartStore.orderNumberCvConfirm];

  cartStore
    .submitOfferCvUpsell(
      cartStore.orderNumberCvConfirm,
      productId,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
      amazonCheckoutSessionId,
      null,
    )
    .then((data: any) => {
      orderStore.resetPageView(cartStore.currentBaseUrl);

      window.location.href = data.redirectUrl;
    });
}

function submitCvXsell() {
  cartStore
    .submitOfferCvXsell(
      cartStore.orderNumberCvConfirm,
      products.value,
      subsOrderData.value,
      specifyDeliveryDate.value,
      shippingCarrierId.value,
      amazonCheckoutSessionId,
      null,
    )
    .then((data: any) => {
      cartStore.$reset();
      orderStore.resetPageView(cartStore.currentBaseUrl);

      window.location.href = data.redirectUrl;
    });
}
</script>
